.header{
    background-color: #1D1E2B;
    height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
}
.tkHteater{
    width: 6vw;
    height: 6vw;
    margin: 0px 0px 0px 11vw;
}
.callHeater{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    width: 20vw;
}
.callTextHeater{
    display: flex;
    flex-direction: column;
    color: white;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: "almoni-dl-regular", Sans-serif;
    font-size: 20px;
    font-weight: 300;
}
.callImg{
    width: 6vw;
    height: 7vh;
    color: white;
}
.headerCountener{
    display: flex;
    flex-wrap: nowrap;
    color: white;
    justify-content: flex-start;
    height: 100%;
    align-items: center;
}
.headerText{
    border-left: 2px solid white;
    padding: 19px;
    font-family: "almoni-dl-regular", Sans-serif;
    font-size: 20px;
    font-weight: 300;
    color: white;
    text-decoration: none;

}
.navbarHeader{
    width: 20vw;
    height: 10vh;
    display: none;
    justify-content: center;
    align-items: center;
}

.navbarHeaderIcon{
    color: white;
    font-size: 2rem;
}

.navbarHeader nav{
    background-color: #1D1E2B;
    width: 100%;
    position: fixed;
    top: 16vw;
    color: white;
    flex-direction: column;
    align-items: center;
}
.navbarHeader nav a{
    display: flex;
    align-content: center;
    height: 5vh;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.2rem 0.5rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
}
.navbarHeader nav div:hover{
    background-color: #515279;
}






.footer{
    background-color: #1D1E2B;
    width: 100vw;
    height: 16vh;
    position: fixed;
    bottom: 0;
    display: flex;
}
.footerSmall{
    display: none;
    width: 100vw;
    height: 10vh;
    position: fixed;
    bottom: 0;
    color: white;
    font-size: 1.5rem;
}
.footerSmallButtonMessage{
   background-color: #FFBD59;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footerSmallButtonCall{
    background-color: #1D1E2B;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.infoFooter{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 20vw;
    color: white;
    justify-content: center;
    align-items: center;
    font-family: "almoni-dl-regular", Sans-serif;
    font-size: 20px;
    font-weight: 300;
}
.textToLeft{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 30px;
}
.textToRight{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 30px;

}
.infoH1Footer{
    color:#FFBD59;
    font-size: 25px;
}
.infoHome{
    width: 100vw;
    height: 100vh;
}
.person{
    width: 20vw;
    height: 20vw;
}
.infoBlock{
    position: absolute;
    bottom: 10vh;
    right: 1vw;
    width: 50vw;
    height: 75vh;
    background-color: rgba(255, 255, 255, 0.5);
}

.close{
    display: none;
}



@media only screen and (max-width: 1024px){
    .headerCountener{
        display: none;
    }
    .callHeater{
        width: 45vw;
    }
    .callImg{
        width: 10vw;
        height: 10vh;
    }
    .navbarHeader{
        display: flex;
    }
    .tkHteater{
        width: 20vw;
        height: 10vh;
        margin: 3px;
    }
    .infoHome{
       width: 100vw;
        height: 100vh;
        background-size: contain;
    }
    .footerSmall{
        display: flex;
    }
    .footer{
        display: none;
    }
    .infoBlock{
        width: 80vw;
        right: 10vw;
    }
      .responsive_nav{
        display: flex;
    }
}




